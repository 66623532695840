import React from "react";
import { Link } from "react-router-dom";

const ArrivalsScreen = () => {
  return (
    <div style={containerStyle}>
      <iframe
        src="https://api.aitl.info/arrivals"
        style={iframeStyle}
        title="Arrivals"
      />
      <div style={buttonContainerStyle}>
        <Link to="/">
          <button style={buttonStyle}>Go to Flights</button>
        </Link>
        <Link to="/departures">
          <button style={buttonStyle}>Go to Departures</button>
        </Link>
      </div>
    </div>
  );
};

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100vw", // Full viewport width
  height: "100vh", // Full viewport height
  backgroundColor: "#f0f0f0", // Optional background color
};

const iframeStyle = {
  width: "100%",
  height: "80%", // Adjust based on how much space you want for the iframe
  border: "none",
};

const buttonContainerStyle = {
  display: "flex",
  justifyContent: "space-around",
  width: "100%",
  padding: "1em",
  backgroundColor: "transparent", // Transparent background for button area
};

const buttonStyle = {
  padding: "0.5em 1em", // Smaller padding for smaller buttons
  fontSize: "1rem",
  backgroundColor: "transparent", // Fully transparent background
  border: "2px solid rgba(255, 255, 255, 0.8)", // White border with slight transparency
  borderRadius: "5px",
  color: "white", // White text to ensure it's visible
  cursor: "pointer",
  transition: "background-color 0.3s ease",
};

// Optional hover effect for buttons
buttonStyle[":hover"] = {
  backgroundColor: "rgba(255, 255, 255, 0.1)", // Very slight background color on hover
};

export default ArrivalsScreen;
