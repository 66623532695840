import React, { Component } from "react";
import PropTypes from "prop-types"; // Import PropTypes

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false }; // Initialize state to track errors
  }

  static getDerivedStateFromError(error) {
    // Update state to display fallback UI in case of an error
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // Log the error (you can log it to an external service like Sentry)
    console.error("Error captured in ErrorBoundary:", error, info);
  }

  render() {
    if (this.state.hasError) {
      // Fallback UI when an error occurs
      return <h2>Something went wrong. Please try again later.</h2>;
    }

    // Render the child components when there's no error
    return this.props.children;
  }
}

// Add PropTypes validation for `children`
ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
