import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import PropTypes from "prop-types";

const AdminPanel = ({ onLogout }) => {
  const [settings, setSettings] = useState({
    logo_path: "",
    logo2_path: "",
    background_image_path: "",
    primary_color: "#0000FF",
    secondary_color: "#FF0000",
    font_size: 16,
    logo_size: 100,
    note_content: "",
    header_font_size: 24,
    table_font_size: 14,
    font_family: "Inter, sans-serif",
    header_font_weight: "bold",
    table_font_weight: "normal",
    header_font_style: "normal",
    table_font_style: "normal",
    text_color: "#000000",
    background_color: "#FFFFFF",
  });
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const api = axios.create({
    baseURL: "/api",
    headers: { "Content-Type": "application/json" },
  });

  const fetchCurrentSettings = useCallback(async () => {
    try {
      const response = await api.get("/admin/settings");
      setSettings(response.data);
    } catch (error) {
      setError("Error fetching current settings");
    }
  }, [api]);

  useEffect(() => {
    fetchCurrentSettings();
  }, [fetchCurrentSettings]);

  const handleInputChange = (e) => {
    setSettings({ ...settings, [e.target.name]: e.target.value });
  };

  const handleFileUpload = async (e, type) => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;
    const formData = new FormData();
    formData.append(type, selectedFile);

    try {
      setMessage(null);
      setError(null);
      const response = await api.post(`/admin/upload-${type}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status === 200) {
        setMessage(`${type} uploaded successfully`);
        setSettings({ ...settings, [`${type}_path`]: response.data.filePath });
        e.target.value = null;
      } else {
        throw new Error(`Failed to upload ${type}`);
      }
    } catch (error) {
      setError(`Error uploading ${type}`);
    }
  };

  const handleSaveSettings = async () => {
    try {
      setMessage(null);
      setError(null);
      const response = await api.put("/admin/settings", settings);
      if (response.status === 200) {
        setMessage("Settings saved successfully");
      } else {
        throw new Error("Failed to save settings");
      }
    } catch (error) {
      setError("Error saving settings");
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen p-8">
      <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-md overflow-hidden">
        <div className="bg-gradient-to-r from-blue-500 to-purple-600 p-6 flex justify-between items-center">
          <h1 className="text-3xl font-bold text-white">Admin Panel</h1>
          <button
            onClick={onLogout}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition duration-300"
          >
            Logout
          </button>
        </div>
        <div className="p-8">
          {message && (
            <div
              className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 mb-6"
              role="alert"
            >
              {message}
            </div>
          )}
          {error && (
            <div
              className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6"
              role="alert"
            >
              {error}
            </div>
          )}

          <form className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <FileUploadField
                label="Upload Logo"
                id="logo"
                onChange={(e) => handleFileUpload(e, "logo")}
                preview={settings.logo_path}
              />
              <FileUploadField
                label="Upload Secondary Logo"
                id="logo2"
                onChange={(e) => handleFileUpload(e, "logo2")}
                preview={settings.logo2_path}
              />
            </div>

            <FileUploadField
              label="Upload Background"
              id="background"
              onChange={(e) => handleFileUpload(e, "background")}
              preview={settings.background_image_path}
            />

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <ColorField
                label="Primary Color"
                id="primary_color"
                value={settings.primary_color}
                onChange={handleInputChange}
              />
              <ColorField
                label="Secondary Color"
                id="secondary_color"
                value={settings.secondary_color}
                onChange={handleInputChange}
              />
              <ColorField
                label="Text Color"
                id="text_color"
                value={settings.text_color}
                onChange={handleInputChange}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <NumberField
                label="Base Font Size"
                id="font_size"
                value={settings.font_size}
                onChange={handleInputChange}
                min={8}
                max={32}
              />
              <NumberField
                label="Header Font Size"
                id="header_font_size"
                value={settings.header_font_size}
                onChange={handleInputChange}
                min={16}
                max={48}
              />
              <NumberField
                label="Table Font Size"
                id="table_font_size"
                value={settings.table_font_size}
                onChange={handleInputChange}
                min={8}
                max={24}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <SelectField
                label="Font Family"
                id="font_family"
                value={settings.font_family}
                onChange={handleInputChange}
                options={[
                  { value: "Inter, sans-serif", label: "Inter" },
                  { value: "Arial, sans-serif", label: "Arial" },
                  { value: "Helvetica, sans-serif", label: "Helvetica" },
                  { value: "Georgia, serif", label: "Georgia" },
                ]}
              />
              <NumberField
                label="Logo Size"
                id="logo_size"
                value={settings.logo_size}
                onChange={handleInputChange}
                min={50}
                max={200}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <SelectField
                label="Header Font Weight"
                id="header_font_weight"
                value={settings.header_font_weight}
                onChange={handleInputChange}
                options={[
                  { value: "normal", label: "Normal" },
                  { value: "bold", label: "Bold" },
                ]}
              />
              <SelectField
                label="Table Font Weight"
                id="table_font_weight"
                value={settings.table_font_weight}
                onChange={handleInputChange}
                options={[
                  { value: "normal", label: "Normal" },
                  { value: "bold", label: "Bold" },
                ]}
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <SelectField
                label="Header Font Style"
                id="header_font_style"
                value={settings.header_font_style}
                onChange={handleInputChange}
                options={[
                  { value: "normal", label: "Normal" },
                  { value: "italic", label: "Italic" },
                ]}
              />
              <SelectField
                label="Table Font Style"
                id="table_font_style"
                value={settings.table_font_style}
                onChange={handleInputChange}
                options={[
                  { value: "normal", label: "Normal" },
                  { value: "italic", label: "Italic" },
                ]}
              />
            </div>

            <div>
              <label
                htmlFor="note_content"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Announcement
              </label>
              <textarea
                id="note_content"
                name="note_content"
                value={settings.note_content}
                onChange={handleInputChange}
                rows={4}
                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>

            <div>
              <button
                type="button"
                onClick={handleSaveSettings}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-300"
              >
                Save All Settings
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

AdminPanel.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

const FileUploadField = ({ label, id, onChange, preview }) => (
  <div>
    <label
      htmlFor={id}
      className="block text-sm font-medium text-gray-700 mb-2"
    >
      {label}
    </label>
    <input
      type="file"
      id={id}
      onChange={onChange}
      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
    />
    {preview && (
      <img src={preview} alt={label} className="mt-2 h-20 object-contain" />
    )}
  </div>
);

FileUploadField.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  preview: PropTypes.string,
};

const ColorField = ({ label, id, value, onChange }) => (
  <div>
    <label
      htmlFor={id}
      className="block text-sm font-medium text-gray-700 mb-2"
    >
      {label}
    </label>
    <input
      type="color"
      id={id}
      name={id}
      value={value}
      onChange={onChange}
      className="mt-1 block w-full h-10 rounded-md"
    />
  </div>
);

ColorField.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const NumberField = ({ label, id, value, onChange, min, max }) => (
  <div>
    <label
      htmlFor={id}
      className="block text-sm font-medium text-gray-700 mb-2"
    >
      {label}
    </label>
    <input
      type="number"
      id={id}
      name={id}
      value={value}
      onChange={onChange}
      min={min}
      max={max}
      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
    />
  </div>
);

NumberField.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
};

const SelectField = ({ label, id, value, onChange, options }) => (
  <div>
    <label
      htmlFor={id}
      className="block text-sm font-medium text-gray-700 mb-2"
    >
      {label}
    </label>
    <select
      id={id}
      name={id}
      value={value}
      onChange={onChange}
      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

SelectField.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default AdminPanel;
