import React, { createContext, useReducer, useMemo } from "react";
import PropTypes from "prop-types";

// Initial state
const initialState = {
  user: null,
  theme: "light",
};

// Create context
export const GlobalContext = createContext(initialState);

// Reducer function to handle state updates
const globalReducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return { ...state, user: action.payload };
    case "SET_THEME":
      return { ...state, theme: action.payload };
    default:
      console.warn(`Unhandled action type: ${action.type}`);
      return state;
  }
};

// Global provider component
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalReducer, initialState);

  // Memoize the value to prevent unnecessary re-renders
  const value = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

// Add PropTypes validation for `children`
GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
