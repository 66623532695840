import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App"; // Main App component
import { GlobalProvider } from "./context/GlobalContext"; // Global context provider
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing"; // Sentry integration for error tracking and performance monitoring
import "./index.css"; // Global CSS styles

// Initialize Sentry for error tracking, only if in production
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN, // Ensure DSN is configurable via environment variables
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0, // Adjust this rate for performance vs. sampling
    environment: "production", // Track environment (development, production)
  });
}

// Find the root element in the DOM
const root = ReactDOM.createRoot(document.getElementById("root"));

// Render the app within React.StrictMode and the GlobalProvider
root.render(
  <React.StrictMode>
    <GlobalProvider>
      <App />
    </GlobalProvider>
  </React.StrictMode>
);

// Optional: If you want to add service workers for PWA support, you can uncomment below
// import * as serviceWorker from './serviceWorker';
// serviceWorker.register(); // Use register() for enabling service workers
