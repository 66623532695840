import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import PropTypes from "prop-types";
import { GlobalProvider } from "./context/GlobalContext";
import ErrorBoundary from "./components/ErrorBoundary";
import "./assets/styles.css";
import FlightInterfaceScreen from "./screens/FlightInterfaceScreen";
import ArrivalsScreen from "./screens/ArrivalsScreen";
import DeparturesScreen from "./screens/DeparturesScreen";
import LoginScreen from "./screens/LoginScreen";
import AdminPanel from "./components/AdminPanel";
import axios from "axios";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    checkAuthStatus();
  }, []);

  const checkAuthStatus = async () => {
    try {
      const response = await axios.get("/api/auth-status");
      setIsAuthenticated(response.data.isLoggedIn);
    } catch (error) {
      console.error("Error checking auth status:", error);
      setError("Failed to check authentication status. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
    setError(null);
  };

  const handleLogout = async () => {
    try {
      await axios.post("/api/logout");
      setIsAuthenticated(false);
    } catch (error) {
      console.error("Error logging out:", error);
      setError("Failed to log out. Please try again.");
    }
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorDisplay message={error} />;
  }

  return (
    <GlobalProvider>
      <div className="App">
        <ErrorBoundary>
          <Router>
            <Routes>
              <Route path="/" element={<FlightInterfaceScreen />} />
              <Route path="/arrivals" element={<ArrivalsScreen />} />
              <Route path="/departures" element={<DeparturesScreen />} />
              <Route
                path="/login"
                element={
                  isAuthenticated ? (
                    <Navigate to="/admin" replace />
                  ) : (
                    <LoginScreen onLoginSuccess={handleLoginSuccess} />
                  )
                }
              />
              <Route
                path="/admin"
                element={
                  isAuthenticated ? (
                    <AdminPanel onLogout={handleLogout} />
                  ) : (
                    <Navigate to="/login" replace />
                  )
                }
              />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Router>
        </ErrorBoundary>
      </div>
    </GlobalProvider>
  );
}

const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-screen">
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
  </div>
);

const ErrorDisplay = ({ message }) => (
  <div className="flex justify-center items-center h-screen">
    <div
      className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
      role="alert"
    >
      <strong className="font-bold">Error: </strong>
      <span className="block sm:inline">{message}</span>
    </div>
  </div>
);

ErrorDisplay.propTypes = {
  message: PropTypes.string.isRequired,
};

export default App;
